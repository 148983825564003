import * as React from "react"
import Layout from "../components/layout"
import "./index.css"
import Header from "../components/header"

const title = "Terms of Use"

const TERMS_AND_CONDITIONS_HTML = `
<p><strong>Amazon Emblem Terms of Use</strong></p>
<p><strong>Last updated: [Insert Date]</strong></p>
<p>This is an agreement between you and bluDiagnostics LLC (with its affiliates, "Amazon" or "we"). Before using Emblem, please read these Emblem Terms of Use, the Emblem Privacy Notice,&nbsp; the&nbsp;<a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=202002080">Amazon Device Terms of Use</a>, the&nbsp;<a href="https://www.amazon.com/privacy">Amazon.com Privacy Notice</a>, the&nbsp;<a href="https://www.amazon.com/conditionsofuse">Amazon.com Conditions of Use</a>, and the other applicable rules, policies, and terms posted on the Amazon.com website, available through Emblem (collectively, this "Agreement"). By using Emblem, you agree to be bound by the terms of this Agreement on behalf of yourself and all other persons who use Emblem under your account and profile. If you do not accept the terms of this Agreement, then you may not use Emblem.</p>
<p>For the purpose of these Emblem Terms of Use:</p>
<p>&ldquo;Emblem&rdquo; means the Emblem app, the Emblem Devices, and the Emblem Services.</p>
<p>"Emblem app" means the app provided by Amazon that provides access to Emblem Services, including your test results, Emblem&rsquo;s settings, Emblem-related content, and other information.</p>
<p>"Emblem Devices" means the Amazon Emblem reader instrument and the single-use cartridges that are used in connection with the Emblem app.</p>
<p>&rdquo;Emblem Services" means all health services provided through the Emblem app, which include Third Party Services, Software, the Emblem app, digital content, and support and other related services.</p>
<p>"Software" means all software that we make available to you in connection with Emblem.</p>
<p>"Third Party Service" means any content, applications, or services provided by a third party, including other Amazon businesses, that we make available to you in connection with Emblem.</p>

<ol style="margin:0px 0; padding:0; list-style: decimal inside none;">
<li><strong> Emblem Services and Emblem Devices</strong></li>
<p><strong>1.1. Emblem Services. </strong>Please carefully review product information, including the Fact Sheet and other materials provided with Emblem before using the Emblem Services. Only use Emblem in accordance with its packaging, Instructions for Use, and other labeling, including any [contraindications,] warnings and precautions. Content provided with Emblem describing general principles of health care is for reference only; no content provided with Emblem provides specific healthcare instructions for individuals.</p>
<p><strong>1.2. Emblem Devices and the Emblem app.</strong> Emblem Devices and the Emblem app are required to access and use Emblem Services. By using Emblem, you agree to allow Amazon to track and measure the health data Emblem can collect to provide you the Emblem Services. The Emblem app, a compatible mobile device (such as a smartphone), and an Amazon.com account and Emblem profile are required to use Emblem.</p>
<p>If you are under 18 or over 45, you may not use the Emblem Services. Please see the product labeling for information about age requirements.</p>
<p>If you proceed with a virtual telehealth consult through a Third Party Service, your use of such Third Party Service will be subject to its terms and conditions, notice of privacy practices, and any additional related terms.</p>
<p><strong>1.3. Use of the Software. </strong>You may use the Software only on or through the Emblem app, and you may only make personal and non-commercial use of Emblem. For additional terms that apply to the Software, see the software terms contained in the&nbsp;<a href="https://www.amazon.com/conditionsofuse">Amazon.com Conditions of Use</a>, the&nbsp;<a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=202002080">Amazon Device Terms of Use</a>, and the terms contained in the Legal section of your Emblem app.</p>
<p><strong>1.4. Amazon.com Profile.</strong> You will need an Amazon.com profile to use Emblem. You agree to provide and maintain true, current, and complete information about yourself in the profile. You may establish and use a personal identification number to access and use the Emblem app. If you do not set a personal identification number, other people with whom you share your Amazon account might be able to access information available in the Emblem app, including all health information. Each adult who shares an Amazon.com account with other members of their household will need to create their own profile in order to use Emblem. Only one profile can be connected to the Emblem Devices at a time, and another profile cannot be used with the same Emblem Devices until the prior one has been removed from those Emblem Devices. If you choose to share access to your email associated with your Amazon.com account, those individuals might also be able to see communications we send to you, including order and shipping confirmations, although your test results may be viewed only on the Emblem app.&nbsp; Our Privacy Notice provides more information on how you may receive communications from us.</p>
<p><strong>1.5. Third Party Services.&nbsp; </strong>If you choose to print or share with Third Parties Services data made available to you through Emblem, including, without limitation, health-related information such as test results, the terms and policies, including terms and conditions and privacy policies, of such Third Party Services will apply to such printing or sharing, and you release us from any liability that may arise.</p>

<li><strong> General</strong></li>
<p><strong>2.1. Information.</strong> The Software will provide Amazon with information about your use of Emblem, or information otherwise collected by Emblem (such as device information, software performance, settings changes, whether you interacted with Emblem on a particular day, or the fact that you opened the Emblem app a certain number of times per day). We will handle any information we receive in accordance with the Emblem Privacy Notice and the <a href="https://www.amazon.com/privacy">Amazon.com Privacy Notice</a>.</p>
<p><strong>2.2 Relationship; Content.</strong> Always seek the advice of your health care provider with any questions you may have regarding your test results or other health related issues. In the case of a health emergency, seek immediate assistance. Never delay obtaining medical advice or disregard medical advice based on the use of Emblem. Emblem is not designed or intended to treat, cure, mitigate, or prevent any condition. You should not use information provided by Emblem to replace a relationship with your health care professional and should not rely on such information as professional medical advice. Your use of the Emblem does not create a doctor-patient relationship between Amazon and you.</p>
<p><strong>2.3. Changes to Emblem Services; Amendments.</strong> We may change, suspend, or discontinue&nbsp; Emblem Services, or any part of them, at any time without notice. We may amend any of this Agreement's terms at our sole discretion by posting the revised terms on the Amazon.com website. Your continued use of Emblem Services after the effective date of the revised Agreement constitutes your acceptance of the terms.</p>
<p><strong>2.4. Compliance with Law.</strong> You are responsible for ensuring that your use of Emblem complies with any applicable laws. You may not use Emblem in any manner that does not comply with this Agreement. Without limiting the previous sentence, you may not use Emblem in a manner that is illegal, obscene, defamatory, invades privacy, infringes intellectual property (including publicity rights), or otherwise injures third parties or is objectionable. Amazon is not liable for your use of Emblem.</p>
<p><strong>2.5. Geographic Restrictions.</strong> Emblem is operated in those jurisdictions in which it has government authorization.</p>
<p><strong>2.6. Termination.</strong> Your rights under this Agreement will automatically terminate without notice if you fail to comply with any of its terms. In case of such termination, Amazon may immediately revoke your access to Emblem Services without refund of any fees. Amazon&rsquo;s failure to insist upon or enforce your strict compliance with this Agreement will not constitute a waiver of any of its rights.</p>
<p><strong>2.7 Disputes/Binding Arbitration. Any dispute or claim arising from or relating to this Agreement or Emblem is subject to the dispute resolution, governing law, disclaimer of warranties, limitation of liability, and all other terms in the </strong><a href="https://www.amazon.com/conditionsofuse"><strong>Amazon.com Conditions of Use</strong></a><strong> and </strong><a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=202002080"><strong>Amazon Device Terms of Use</strong></a><strong>. By using Emblem , you agree, on behalf of yourself and all other persons who use Emblem under your account and profile, to be bound by those terms.</strong></p>
<p><strong>2.8. Disclaimer of Warranties and Limitation of Liability. Without limiting the Disclaimer of Warranties and Limitation of Liability terms in the Amazon.com Conditions of Use, and unless otherwise required by applicable law, in no event will our or our licensors' or our service providers' aggregate liability with respect to any claim arising from or relating to this Agreement or your use of Emblem exceed fifty dollars ($50).</strong></p>
<p><strong>2.9. Contact Information.</strong> For help with Emblem or resolving other issues, please contact <a href="https://www.amazon.com/contact-us">Amazon Customer Service</a>.</p>
</ol>
`

const termsOfUse = () => (
  <Layout>
    <Header siteTitle={title} />
    <div className="container3">
      <div className="container4">
        <p dangerouslySetInnerHTML={{ __html: TERMS_AND_CONDITIONS_HTML }} />
      </div>
    </div>
  </Layout>
)

export default termsOfUse
